export const COLORS = {
    navColor: {
      light: 'rgba(255, 198, 0, 0.95)', 
      dark: 'rgba(25, 53, 73, 0.95)',
    },
    // navHoverColor: {
    //   light: 'blue',
    //   dark: 'rgba(33, 70, 97, 0.85)',
    // },
    heroBackground: {
      light: 'radial-gradient(circle, #ffffff  0%, #ffc600 100%',//orange with white
      dark: 'radial-gradient(circle, rgb(58 217 0) 30%, rgb(25 53 73) 100%', //
    },
    monitorBackgroundColor: {
      light: 'rgba(25, 53, 73, 1)',
      dark: 'rgba(25, 53, 73, 1)',
    },
    navTextHoverColor: {
      light: 'white', 
      dark: 'rgba(58, 217, 0, 1)', //successGreen
    },
    navTextColor: {
      light: 'black',
      dark: 'white', 
    },
    buttonBoxShadowColor: {
      light: 'rgba(0, 0, 0, 0.1)', 
      dark: 'rgba(255, 255, 255, 0.1)',
    },
    footerColor: {
      light: '#ffc600', 
      dark: 'rgba(25, 53, 73, 1)', 
    },
    headerHeight: {
      light: '64px',
      dark: '64px', 
    },
    footerHeight: {
      light: '64px', 
      dark: '64px', 
    },
    navFontSize: {
      light: '1.75rem',  
      dark: '1.75rem',  
    },
    navBoxBackgroundColor: {
      light: '#ffc600',
      dark: 'rgba(33, 70, 97, 1)', // lighter than Header
    },
    fontFamily: {
      light: 'Roboto, Sans-Serif', // roboto
      dark: 'monospace', // 'monospace'
    },
    welcomeBackgroundColor: {
      light: 'rgba(0, 0, 0, 0.45)',
      dark: 'rgba(255, 255, 255, 0.45)',
    },
    backgroundColor: {
      light: 'white',
      dark: 'black',
    },
    textColor: {
      light: 'black',
      dark: 'white',
    },
    textColorHover: {
      light: 'white',
      dark: 'black',
    },
    heroBoxShadowColor: {
      light: 'rgba(58, 217, 0, 1)',//success green 58, 217, 0
      dark: 'rgba(58, 217, 0, 1)',
    },
    successGreen: {
      light: 'rgba(58, 217, 0, 1)',
      dark: 'rgba(58, 217, 0, 1)',
    },
    navUnderlineWidth: {
      light: '100%',
      dark: '0%',
    },
    themeSelectorRotate: {
      light: '280deg',
      dark: '260deg',
    },
    sliderBeforeColor: {
      light: '#ffc600',
      dark: 'white',
    },
    sliderBeforeColorHover: {
      light: 'black',
      dark: 'black',
    },
    sliderBeforeBoxShadow: {
      light: '0 0 17px #000',
      dark: '0 0 17px #fff',
    },
    postTitleColor: {
      light: 'rgb(255 198 0 / 100%)',
      // light: 'rgb(255 198 0 / 23%)',
      dark: 'rgb(25 53 73 / 100%)',
      // dark: 'rgb(25 53 73 / 23%)',
    },
    anchorTextHoverColor: {
      light: 'white',
      dark: 'black',
    },
    postTitleSkew: {
      light: '20deg',
      dark: '-20deg',
    },
  };
  
  export const COLOR_MODE_KEY = 'color-mode';
  export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';